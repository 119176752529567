import React from 'react'
import { ShoppingCart } from 'phosphor-react'
import {Link} from 'react-router-dom'

const Navbar = () => {
    return (
    <div className='bg-primary d-flex justify-content-end p-3  fixed-top'> 
    <div className='px-2'> 
    <Link to='/' className='mx-4 text-decoration-none text-light link-dark'>
    Shop
    </Link>
    <Link to='/Cart' className='mx-4 text-light link-dark'>
        <ShoppingCart size={25}/>
    </Link>
    </div>
    </div>
    

    )
}

export default Navbar