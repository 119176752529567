import {Products} from '../../products';
import ProductList from '../../productList';
import './shop.css'

const Shop = (props) => {
    return (
        <div>
            <div className='m-4 p-5 text-primary fs-1 text-center scr'>
                Listed Products
            </div>
            <div>
                {Products.map((product) => 
                    <ProductList data={product} />
                )}
            </div>

        </div>
    )
}

export default Shop