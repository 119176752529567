import React from 'react'

const ProductList = (props) => {
    const { id, name, description, availability, price } = props.data;

    return (
        <div className='row container m-5 border-bottom  p-4 bg-light'>
            <div className='col-4'>
                <img src={name} width={200} alt={name}>
                </img>
            </div>
            <div className='col-8'>
                <p>
                    {description}
                </p>
                <p>
                    <span className='text-danger fs-6'>status</span> {availability}
                </p>
                <p>
                    KSh {price}
                </p>
                <div>
                    <button className='btn btn-success'>
                        add to cart
                    </button>
                </div>
            </div>

        </div>

    )
}

export default ProductList

