import React from "react";
import { Route } from "react-router-dom";
import {BrowserRouter as Router } from "react-router-dom";
import Navbar from "./components/Navbar";
import { Routes } from "react-router-dom";
import Cart from "./pages/cart/cart";
import Shop from "./pages/shop/shop";
import './App.css'

const App = ()=>{
  return(
     <Router>
 <Navbar />
  <Routes>
    <Route path="/" element={<Shop/>}/>
    <Route path="/Cart" element={<Cart/>}/>
  </Routes>
 </Router>
  )

  
}

export default App