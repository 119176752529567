
import brocoli from './assets/images/brocoli.png'
import redonion from './assets/images/redonion.png'
import redchilli from './assets/images/redchilli.png'
import carrot from './assets/images/carrot.png'
import cabbage from './assets/images/cabbage.png'
import cauliflower from './assets/images/cauliflower.png'
import mushroom from './assets/images/mushroom.png'
import chayote from './assets/images/chayote.png'
import cucummber from './assets/images/cucumber.png'
import tomato from './assets/images/tomato.png'
import whiteonion from './assets/images/whiteonion.png'
import potato from './assets/images/poatato.png'
import pumkin from './assets/images/pumkin.png'
import eggplant from './assets/images/eggplant.png'
import bellpepper from './assets/images//bellpepper.png'
import babycorn from './assets/images/babycorn.png'



 export const Products = [
    {
        id: 1,
        name: babycorn,
        description: "sweet babycorn with the fresh and genuinity of the african tropics.",
        availability: "available",
        price: 25
    },

    {
        id: 2,
        name: bellpepper,
        description: "sweet bellpepper with the fresh and genuinity of the african tropics.",
        availability: "available",
        price: 25
    },
    {
        id:1,
        name: brocoli,
        description: "sweet brocoli with the fresh and genuinity of the african tropics.",
        availability: "available",
        price: 25
    },
    {
        id:1,
        name: cabbage,
        description: "sweet cabbage with the fresh and genuinity of the african tropics.",
        availability: "available",
        price: 25
    },
    {
        id:1,
        name: carrot,
        description: "sweet carrot with the fresh and genuinity of the african tropics.",
        availability: "available",
        price: 25
    },
    {
        id:1,
        name: cauliflower,
        description: "sweet cauliflower with the fresh and genuinity of the african tropics.",
        availability: "available",
        price: 25
    },
    {
        id:1,
        name: chayote,
        description: "sweet chayote with the fresh and genuinity of the african tropics.",
        availability: "available",
        price: 25
    },
    {
        id:1,
        name: cucummber,
        description: "sweet cucummber with the fresh and genuinity of the african tropics.",
        availability: "available",
        price: 25
    },
    {
        id:1,
        name: eggplant,
        description: "sweet eggplant with the fresh and genuinity of the african tropics.",
        availability: "available",
        price: 25
    },
    {
        id:1,
        name: mushroom,
        description: "sweet mushroom with the fresh and genuinity of the african tropics.",
        availability: "available",
        price: 25
    },
    {
        id:1,
        name: potato,
        description: "sweet potato with the fresh and genuinity of the african tropics.",
        availability: "available",
        price: 25
    },
    {
        id:1,
        name: pumkin,
        description: "sweet pumkin with the fresh and genuinity of the african tropics.",
        availability: "available",
        price: 25
    },
    {
        id:1,
        name: redchilli,
        description: "sweet redchilli with the fresh and genuinity of the african tropics.",
        availability: "available",
        price: 25
    },
    {
        id:1,
        name: redonion,
        description: "sweet redonion with the fresh and genuinity of the african tropics.",
        availability: "available",
        price: 25
    },
    {
        id:1,
        name: tomato,
        description: "sweet tomato with the fresh and genuinity of the african tropics.",
        availability: "available",
        price: 25
    },
    {
        id:1,
        name: whiteonion,
        description: "sweet whiteonion with the fresh and genuinity of the african tropics.",
        availability: "available",
        price: 25
    }
]


